import { Organisation, Tier } from "common/build/prisma/client";
import { getData, patchData } from "./rest";
import { ListParameters } from "../../../../common/build/api-parameters/common";
import { objectToQueryString } from "../../utils/api";

export const get = async (userId: number): Promise<Organisation & { Tier: Tier }> =>
    await getData(`/organisation/by-user/${userId}`);

export const list = async (args: ListParameters): Promise<{ items: Organisation[]; total: number }> =>
    await getData(`/organisation${objectToQueryString(args)}`);

export const archiveOrg = async (id: number): Promise<Response> => await patchData(`/organisation/archive/${id}`);

export const restoreOrg = async (id: number): Promise<Response> => await patchData(`/organisation/restore/${id}`);
