import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { useCrud } from "./contexts/crud.context";
import { useList } from "./contexts/list.context";
import { PagedList } from "./PagedList";
import { Header } from "../../../components/layout/Header/Header";
import { Alert } from "@material-ui/lab";
import { SimpleBlankSlate } from "../SimpleBlankSlate";
import barchartImg from "../../../img/barchart.svg";
import { VCard } from "../VCard";
import { useMobile } from "../../../hooks/useMobile";
import { CSVExport } from "../../../components/common/CSVExport";

type ListProps = {
    pagination?: boolean;
    canView?: boolean;
    canAdd?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
    canExport?: boolean;
    searchable?: boolean;
    listComponent?: React.FC;
    includeHeader?: boolean;
    padding?: number;
};

export const List = <T extends Record<string, unknown>>(props: ListProps): JSX.Element => {
    const {
        pagination = true,
        canView,
        canEdit,
        canAdd,
        canDelete,
        canExport,
        searchable = false,
        listComponent: ListComponent = PagedList,
        includeHeader = true,
        padding = 3,
    } = props;

    const {
        total,
        loading,
        error,
        handleSearchTermChange,
        handleFilterChange,
        handleOrderChange,
        handleLocationFilterChange,
        handleActivityFilterChange,
        handleOrgArchiveFilterChange,
        handleRowDelete,
        handleRowRestore,
        fetchExportList,
        handleStartDateChange,
        handleEndDateChange,
        plural,
    } = useList<T>();

    const { model, orgFilterPermission, programmeFilter, userFilter, canOrder, canFilterLocation, canFilterActivity } =
        useCrud<T>();

    const { url } = useRouteMatch();
    const history = useHistory();
    const { isMobile, isTablet } = useMobile();

    const columnLabels = Object.entries(model)
        .filter(([key, { inTable = true, inExport = false }]) => (inTable || inExport) && key !== "roleId")
        .map(([, { label }]) => label);

    const [userStatus, setUserStatus] = useState<string | undefined>("Active");

    const handleUserStatusChange = (status: string) => {
        setUserStatus(status);
    };

    const handleRowClick = (params: T & { id: number }) => {
        const { id } = params;
        if (canView) {
            history.push(`${url}/view/${id}`);
            return;
        }

        if (canEdit) {
            history.push(`${url}/edit/${id}`);
            return;
        }
    };

    const handleExport = () => {
        fetchExportList().then((items) => {
            const headers = Object.entries(model)
                .filter(([, { inTable = true, inExport = false }]) => inTable || inExport)
                .map(
                    ([property, { label = `${property[0].toUpperCase() + property.substring(1).toLowerCase()}` }]) =>
                        label,
                );

            const exportItems = items.map((data) => {
                return Object.entries(model)
                    .filter(([, { inTable = true, inExport = false }]) => inTable || inExport)
                    .map(
                        ([property, { decorator = (value: string) => value, inTable = true, inExport = false }]) =>
                            (inTable || inExport) &&
                            decorator(data[property] as string, property, data as unknown as T),
                    );
            });

            CSVExport(headers, exportItems, `${plural}_Export`);
        });
    };

    return (
        <>
            <Box p={padding} className={plural.toLocaleLowerCase() + "List"}>
                {!isTablet && isMobile && <div style={{ marginTop: 64 }} />}
                {includeHeader && (
                    <Header
                        title={plural}
                        total={!loading && total ? `${total}` : undefined}
                        onAdd={canAdd ? () => history.push(`${url}/add`) : undefined}
                        onSearch={searchable ? handleSearchTermChange : undefined}
                        onFilter={handleFilterChange}
                        onOrder={handleOrderChange}
                        onLocationFilter={handleLocationFilterChange}
                        onActivityFilter={handleActivityFilterChange}
                        onOrgArchiveChange={handleOrgArchiveFilterChange}
                        onStartDate={handleStartDateChange}
                        onEndDate={handleEndDateChange}
                        orgFilterPermission={orgFilterPermission}
                        programmeFilter={programmeFilter}
                        userFilter={userFilter}
                        canOrder={canOrder}
                        canFilterLocation={canFilterLocation}
                        canFilterActivity={canFilterActivity}
                        columnTitles={columnLabels}
                        userStatus={userStatus}
                        onUserStatusChange={handleUserStatusChange}
                    />
                )}
                {!loading && total === 0 ? (
                    <Box mb={2}>
                        <VCard>
                            <SimpleBlankSlate
                                header={plural}
                                image={<img src={barchartImg} />}
                                subHeader={`No ${plural} have been added`}
                            />
                        </VCard>
                    </Box>
                ) : (
                    <ListComponent
                        model={model}
                        onRowClick={handleRowClick}
                        pagination={pagination}
                        onExport={canExport ? handleExport : null}
                        onRowDelete={canDelete ? handleRowDelete : undefined}
                        onRowRestore={canDelete ? handleRowRestore : undefined}
                        canDelete={canDelete}
                        userStatus={userStatus}
                    />
                )}

                {error && (
                    <Box mb={3}>
                        <Alert severity="error">{error}</Alert>
                    </Box>
                )}
            </Box>
        </>
    );
};
export default List;
