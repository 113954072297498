import { Permission } from "common/build/prisma/client";
import React, { PropsWithChildren, useCallback, useState } from "react";
import { Model } from "../Crud";

export interface CrudContextI<T extends Record<string, unknown>> {
    noun: string;
    plural: string;
    model: Model<T>;
    loading: boolean;
    add: (values: T) => Promise<void>;
    update: (id: number, values: T) => Promise<void>;
    deleteItem: (id: number) => Promise<void>;
    orgFilterPermission?: Permission;
    programmeFilter?: boolean;
    userFilter?: boolean;
    canOrder?: boolean;
    canFilterLocation?: boolean;
    canFilterActivity?: boolean;
    canDelete?: boolean;
    orgCanFilterArchive?: boolean;
}

type useCrudProps<T extends Record<string, unknown>> = PropsWithChildren<{
    noun: string;
    plural?: string;
    model: Model<T>;
    addItem?: (values: T) => Promise<Response>;
    updateItem?: (id: number, values: T) => Promise<Response>;
    deleteData?: (id: number) => Promise<Response>;
    orgFilterPermission?: Permission;
    programmeFilter?: boolean;
    userFilter?: boolean;
    canOrder?: boolean;
    canFilterLocation?: boolean;
    canFilterActivity?: boolean;
    canDelete?: boolean;
    orgCanFilterArchive?: boolean;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CrudContext = React.createContext({} as CrudContextI<any>);

export function CrudProvider<T extends Record<string, unknown>>(props: useCrudProps<T>): JSX.Element {
    const {
        noun,
        plural = `${noun}s`,
        model,
        updateItem,
        addItem,
        deleteData,
        children,
        orgFilterPermission,
        programmeFilter,
        userFilter,
        canOrder,
        canFilterLocation,
        canFilterActivity,
        canDelete,
        orgCanFilterArchive,
    } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const add = useCallback((newItem: T) => {
        return new Promise<void>((resolve, reject) => {
            if (!addItem) {
                reject(`Unable to add ${noun}`);
                return;
            }

            setLoading(true);

            addItem(newItem)
                .then(async (response) => {
                    if (response.ok) {
                        resolve();
                    } else {
                        const responseBody = await response.json();
                        reject((responseBody && responseBody.message) ?? `Unable to add ${noun}`);
                    }
                })
                .catch(() => {
                    reject(`Unable to add ${noun}`);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const update = useCallback((id: number, editedItem: T) => {
        return new Promise<void>((resolve, reject) => {
            if (!updateItem) {
                reject(`Unable to update ${noun}`);
                return;
            }

            setLoading(true);

            updateItem(id, editedItem)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject(`Unable to update ${noun} with id: ${id}`);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const deleteItem = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            if (!deleteData) {
                reject(`Unable to delete ${noun} with id: ${id}`);
                return;
            }
            setLoading(true);

            deleteData(id)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject(`Unable to delete ${noun} with id: ${id}`);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    return (
        <CrudContext.Provider
            value={{
                noun,
                plural,
                model,
                loading,
                add,
                update,
                deleteItem,
                orgFilterPermission,
                programmeFilter,
                userFilter,
                canOrder,
                canFilterLocation,
                canFilterActivity,
                canDelete,
                orgCanFilterArchive,
            }}
        >
            {children}
        </CrudContext.Provider>
    );
}

export function useCrud<T extends Record<string, unknown>>(): CrudContextI<T> {
    return React.useContext(CrudContext);
}
