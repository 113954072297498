import React, { useState } from "react";
import Crud from "../../../core/components/crud/Crud";
import { getOrganisationsList, getOrganisationsDetail, getOrganisationsOverview } from "../../../client/api/stats";
import { getCollectionsList } from "../../../client/api/stats";
import { CollectionsList } from "common/build/api-parameters/stats";
import { useAuth } from "../../../contexts/auth.context";
import { organisationsCRUDModel } from "../contexts/organisations.context";
import { Redirect } from "react-router-dom";
import { OrganisationsList, OrganisationsOverview } from "common/build/api-parameters/stats";
import { Box, Typography, ImageList, ImageListItem, Grid } from "@material-ui/core";
import { HeadlineFigureWidget } from "../../dashboard/Widgets/Headlines/HeadlineFigureWidget";
import Spinner from "../../layout/Spinner";
import { useMobile } from "../../../hooks/useMobile";
import { useHomeStyles } from "../../home/Home.style";
import { verbalTheme } from "../../layout/themes/verbal.theme";

export const OrganisationsScreen = (): JSX.Element => {
    const { user } = useAuth();
    const [overview, setOverview] = useState<OrganisationsOverview>();
    const [overviewLoading, setOverviewLoading] = useState<boolean>(false);
    const [mostPopularCollection, setMostPopularCollection] = useState<Record<string, string>>();
    const [collectionsLoading, setCollectionsLoading] = useState<boolean>(false);

    if (!user) {
        return <Redirect to="403" />;
    }

    const { isTablet, isMobile } = useMobile();
    const classes = useHomeStyles();

    if (!overview && !overviewLoading) {
        setOverviewLoading(true);
        getOrganisationsOverview({}).then((overview: OrganisationsOverview) => {
            setOverview(overview);
            setOverviewLoading(false);
        });
    }

    if (!mostPopularCollection && !collectionsLoading) {
        setCollectionsLoading(true);
        getCollectionsList({ take: 1, skip: 0 }).then((data: { items: CollectionsList[]; total: number }) => {
            setMostPopularCollection({
                name: data && data.total > 0 ? data.items[0].name : "-",
                uses: data && data.total > 0 ? `Used in ${data.items[0].programmeCount} programmes` : "",
            });
            setCollectionsLoading(false);
        });
    }

    return (
        <>
            <Box mb={1} p={3}>
                <Typography
                    variant="h3"
                    color="textPrimary"
                    align="left"
                    style={{ paddingRight: verbalTheme.spacing(3) }}
                >
                    Organisations Overview
                </Typography>
            </Box>
            <Grid container spacing={3}>
                <Box p={3} width={1}>
                    <ImageList
                        id="your-activity"
                        gap={10}
                        rowHeight={"auto"}
                        className={isMobile ? classes.imageList : ""}
                        cols={isTablet ? 3.5 : isMobile ? 2.5 : 6}
                    >
                        <ImageListItem>
                            <HeadlineFigureWidget header={"Total Organisations"}>
                                <Box pb={!isTablet && isMobile ? 1 : 0}>
                                    {overviewLoading ? (
                                        <Spinner />
                                    ) : (
                                        <Typography variant="h2">{overview?.totalOrganisations}</Typography>
                                    )}
                                </Box>
                                <Box p={!isTablet && isMobile ? 2 : 1}>
                                    <Typography variant="body2"></Typography>
                                </Box>
                            </HeadlineFigureWidget>
                        </ImageListItem>
                        <ImageListItem>
                            <HeadlineFigureWidget header={"Total Active Subscriptions"}>
                                <Box pb={!isTablet && isMobile ? 1 : 0}>
                                    {overviewLoading ? (
                                        <Spinner />
                                    ) : (
                                        <Typography variant="h2">{overview?.totalActiveSubscriptions}</Typography>
                                    )}
                                </Box>
                                <Box p={!isTablet && isMobile ? 2 : 1}>
                                    <Typography variant="body2"></Typography>
                                </Box>
                            </HeadlineFigureWidget>
                        </ImageListItem>
                        <ImageListItem>
                            <HeadlineFigureWidget header={"Total Users"}>
                                <Box pb={!isTablet && isMobile ? 1 : 0}>
                                    {overviewLoading ? (
                                        <Spinner />
                                    ) : (
                                        <Typography variant="h2">{overview?.totalUsers}</Typography>
                                    )}
                                </Box>
                                <Box p={!isTablet && isMobile ? 2 : 1}>
                                    {overviewLoading ? (
                                        <Spinner />
                                    ) : (
                                        <Typography variant="body2">{overview?.totalActiveUsers} Active</Typography>
                                    )}
                                </Box>
                            </HeadlineFigureWidget>
                        </ImageListItem>
                        <ImageListItem>
                            <HeadlineFigureWidget header={"Total Children"}>
                                <Box pb={!isTablet && isMobile ? 1 : 0}>
                                    {overviewLoading ? (
                                        <Spinner />
                                    ) : (
                                        <Typography variant="h2">{overview?.totalParticipantsInClassrooms}</Typography>
                                    )}
                                </Box>
                                <Box p={!isTablet && isMobile ? 2 : 1}>
                                    <Typography variant="body2">(In Classrooms)</Typography>
                                </Box>
                            </HeadlineFigureWidget>
                        </ImageListItem>
                        <ImageListItem>
                            <HeadlineFigureWidget header={"Total Mood Tracker Usage"}>
                                <Box pb={!isTablet && isMobile ? 1 : 0}>
                                    {overviewLoading ? (
                                        <Spinner />
                                    ) : (
                                        <Typography variant="h2">{overview?.totalDaysMoodsTracked}</Typography>
                                    )}
                                </Box>
                                <Box p={!isTablet && isMobile ? 2 : 1}>
                                    <Typography variant="body2"></Typography>
                                </Box>
                            </HeadlineFigureWidget>
                        </ImageListItem>
                        <ImageListItem>
                            <HeadlineFigureWidget header={"Most Used Collection"}>
                                <Box pb={!isTablet && isMobile ? 1 : 0}>
                                    {collectionsLoading ? (
                                        <Spinner />
                                    ) : (
                                        <Typography variant="body1">{mostPopularCollection?.name}</Typography>
                                    )}
                                </Box>
                                <Box p={!isTablet && isMobile ? 2 : 1}>
                                    <Typography variant="body2">{mostPopularCollection?.uses}</Typography>
                                </Box>
                            </HeadlineFigureWidget>
                        </ImageListItem>
                    </ImageList>
                </Box>
            </Grid>
            <Crud<OrganisationsList, Record<string, unknown>>
                model={organisationsCRUDModel}
                noun="Organisation"
                plural="Organisations"
                getData={getOrganisationsList}
                getItem={getOrganisationsDetail}
                labelProperty="name"
                searchable
                canAdd={false}
                canExport={true}
                canFilterLocation={true}
                orgCanFilterArchive={true}
                viewOnClick
            />
        </>
    );
};
