import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
    CircularProgress,
    Box,
    createStyles,
    Grid,
    makeStyles,
    Tab,
    Tabs,
    Typography,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import { TabPanel } from "../../programme/screens/ViewProgramme";
import { getOrganisationsDetail, getOrganisationsUserList } from "../../../client/api/stats";
import { OrganisationsList } from "common/build/api-parameters/stats";
import dayjs from "dayjs";
import { UserType } from "../../../models/modelTypes";
import { UserListParameters } from "../../../../../common/build/api-parameters/users";
import { Crud } from "../../../core/components/crud/Crud";
import { get } from "../../../client/api/users";
import { organisationUsersModel } from "../contexts/users.context";
import { ListParameters } from "../../../../../common/build/api-parameters/common";
import { NumberStatWidget } from "../components/numberstat";
import { ArrowBackIos, Delete, RestoreFromTrash } from "@material-ui/icons";
import { VTextLink } from "../../common/VTextLink";
import { DestructiveButton, SecondaryButton } from "../../layout/Button/Button";
import { archiveOrg, restoreOrg } from "../../../client/api/organisation";
import { useAuth } from "../../../contexts/auth.context";
import { useAlert } from "../../../contexts/alert.context";

export const ViewOrganisation = (): JSX.Element => {
    const { user } = useAuth();
    const { setAlert } = useAlert();
    const [tabValue, setTabValue] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [organisation, setOrganisation] = useState<OrganisationsList>();
    const [confirmArchive, setConfirmArchive] = useState(false);

    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    if (!loading && !organisation) {
        setLoading(true);
        getOrganisationsDetail(parseInt(id), {}).then((value: OrganisationsList) => {
            setOrganisation(value);
            setLoading(false);
        });
    }

    const useStyles = makeStyles(() =>
        createStyles({
            accordion: {
                padding: "0 16px 0 16px",
                marginBottom: "16px!important",
                backgroundColor: "rgba(0,0,0,0.05)",
                width: "100%",
            },
            accordionHeader: {
                minHeight: "64px",
            },
            header: {
                backgroundColor: "#F9F9FB",
            },
            textBreak: {
                whiteSpace: "pre-line",
            },
            headerColor: {
                backgroundColor: "#F9F9FB",
            },
        }),
    );
    const classes = useStyles();
    const listWrapper = async (args: ListParameters): Promise<{ items: UserType[]; total: number }> =>
        await getOrganisationsUserList(parseInt(id), args);

    return (
        <>
            <Box className={classes.headerColor}>
                <Box p={3}>
                    <Grid container item xs={12} alignItems="center" justifyContent="space-between" spacing={3}>
                        <Grid item xs={6}>
                            <Typography variant="h3" color="textPrimary" align="left">
                                Organisation Details
                            </Typography>
                        </Grid>
                        {user?.Organisation?.id !== organisation?.id ? (
                            <Grid item xs={6}>
                                <DestructiveButton onClick={() => setConfirmArchive(true)}>
                                    {organisation?.deleted ? "Restore" : "Archive"}
                                </DestructiveButton>
                            </Grid>
                        ) : null}
                        <Grid item container xs={12} alignContent="center" alignItems="center">
                            <VTextLink to="/stats/organisations">
                                <Grid item container xs={12} alignContent="center" alignItems="center">
                                    <ArrowBackIos></ArrowBackIos>
                                    <Typography variant="body2" color="textPrimary" align="left">
                                        Return to Organisation List
                                    </Typography>
                                </Grid>
                            </VTextLink>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"h4"}>{organisation?.name}</Typography>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} lg alignItems="center" justifyContent="space-between">
                                <NumberStatWidget
                                    header="Total Registered Users"
                                    stat={`${organisation?.totalUsers}`}
                                    loading={loading}
                                ></NumberStatWidget>
                            </Grid>
                            <Grid item xs={12} sm={6} lg alignItems="center" justifyContent="space-between">
                                <NumberStatWidget
                                    header="Total Active Users"
                                    stat={`${organisation?.totalActiveUsers}`}
                                    loading={loading}
                                ></NumberStatWidget>
                            </Grid>
                            <Grid item xs={12} sm={6} lg alignItems="center" justifyContent="space-between">
                                <NumberStatWidget
                                    header="Total Participants"
                                    stat={`${organisation?.totalParticipants}`}
                                    secondarytext={`(${organisation?.totalParticipantsInClassrooms} In Classrooms)`}
                                    loading={loading}
                                ></NumberStatWidget>
                            </Grid>
                            <Grid item xs={12} sm={6} lg alignItems="center" justifyContent="space-between">
                                <NumberStatWidget
                                    header="Mood Tracker Usage"
                                    stat={`${organisation?.totalDaysMoodsTracked}`}
                                    secondarytext="(Days)"
                                    loading={loading}
                                ></NumberStatWidget>
                            </Grid>
                            <Grid item xs={12} sm={12} lg alignItems="center" justifyContent="space-between">
                                <NumberStatWidget
                                    header="Latest Login"
                                    stat={dayjs(organisation?.dateUserLastAccessed).format("D MMM YYYY [at] HH:mm")}
                                    reduceFont={true}
                                    loading={loading}
                                ></NumberStatWidget>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Tabs
                value={tabValue}
                onChange={(_event: React.ChangeEvent<unknown>, newValue: number) => setTabValue(newValue)}
                indicatorColor="secondary"
                textColor="primary"
                className={classes.headerColor}
            >
                <Tab label="Details" id="details-tab" />
                <Tab label="Users" id="meta-tab" />
            </Tabs>
            <TabPanel index={0} value={tabValue}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant={"h4"}>Address</Typography>
                                {[
                                    organisation?.addressLine1,
                                    organisation?.addressLine2,
                                    organisation?.city,
                                    organisation?.postcode,
                                    organisation?.country,
                                ]
                                    .filter(Boolean)
                                    .map((addressPart, index) => (
                                        <Typography key={`addressPart_${index}`}>{addressPart}</Typography>
                                    ))}
                            </Grid>
                            <Grid container item xs={12} alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Typography variant={"h4"}>Phone No</Typography>
                                    <Typography>{organisation?.phoneNo}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Typography variant={"h4"}>Tier</Typography>
                                    <Typography>{organisation?.tier}</Typography>
                                    {organisation?.trialEndDate ? (
                                        <Typography variant="subtitle2">
                                            Trial ends on
                                            {dayjs(organisation?.trialEndDate * 1000).format(" D MMM YYYY [at] HH:mm")}
                                        </Typography>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </TabPanel>
            <TabPanel index={1} value={tabValue}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Crud<UserType, UserListParameters>
                            model={organisationUsersModel}
                            noun="User"
                            getData={listWrapper}
                            getItem={get}
                            labelProperty={"name"}
                            listParams={{ includeRole: true }}
                            userFilter={true}
                            searchable
                            canExport={true}
                            canAdd={false}
                            canDelete={true}
                            canOrder={true}
                            canFilterActivity={true}
                        />
                    </>
                )}
            </TabPanel>
            <Dialog open={confirmArchive} onClose={() => setConfirmArchive(false)}>
                <DialogTitle>{organisation?.deleted ? "Restore" : "Archive"} Organisation?</DialogTitle>
                <DialogContent>
                    <DialogContentText>{`Are you sure you want to ${
                        organisation?.deleted ? "restore" : "archive"
                    } this organisation?`}</DialogContentText>
                    {organisation?.deleted ? (
                        <DialogContentText>
                            If you restore this organisation, user within the organisation will be able to log in and
                            use the platform. Any data will also be restored. If you choose to delete this classroom
                            again, it will be archived for 30 days then it will be deleted permanently
                        </DialogContentText>
                    ) : (
                        <DialogContentText>
                            If you archive this organisation it will be archived for 30 days. You can restore the
                            organisation at any time before 30 days. After 30 days the organisation will be permanently
                            deleted. Users in the organisation will not be able to use the platform
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <DestructiveButton
                        startIcon={organisation?.deleted ? <RestoreFromTrash /> : <Delete />}
                        onClick={() => {
                            if (organisation) {
                                if (organisation.deleted) {
                                    restoreOrg(organisation.id).then(() => {
                                        setAlert("Organisation successfully restored", "success");
                                        history.push(`/stats/organisations/`);
                                    });
                                } else {
                                    archiveOrg(organisation.id).then(() => {
                                        setAlert("Organisation successfully archived.", "success");
                                        setConfirmArchive(false);
                                        history.push(`/stats/organisations`);
                                    });
                                }
                            }
                        }}
                    >
                        {organisation?.deleted ? "Restore" : "Archive"} Organisation
                    </DestructiveButton>
                    <SecondaryButton
                        onClick={() => {
                            setConfirmArchive(false);
                            //console.log(classroom);
                        }}
                    >
                        Cancel
                    </SecondaryButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
