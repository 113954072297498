
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  getRuntime,
} = require('./runtime/index-browser.js')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.18.0
 * Query Engine version: 4c784e32044a8a016d99474bd02a3b6123742169
 */
Prisma.prismaVersion = {
  client: "5.18.0",
  engine: "4c784e32044a8a016d99474bd02a3b6123742169"
}

Prisma.PrismaClientKnownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientKnownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientUnknownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientRustPanicError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientRustPanicError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientInitializationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientInitializationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientValidationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientValidationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.NotFoundError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`NotFoundError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`sqltag is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.empty = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`empty is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.join = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`join is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.raw = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`raw is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.getExtensionContext is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.defineExtension = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.defineExtension is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.UserScalarFieldEnum = {
  id: 'id',
  email: 'email',
  hasEmailConsent: 'hasEmailConsent',
  cookie: 'cookie',
  password: 'password',
  visitCount: 'visitCount',
  registrationDate: 'registrationDate',
  lastVisited: 'lastVisited',
  firstName: 'firstName',
  lastName: 'lastName',
  isOnboarding: 'isOnboarding',
  passwordResetToken: 'passwordResetToken',
  passwordReetExpiry: 'passwordReetExpiry',
  howYouFoundUs: 'howYouFoundUs',
  roleId: 'roleId',
  organisationId: 'organisationId',
  deleted: 'deleted',
  moodleUsername: 'moodleUsername',
  moodleUserId: 'moodleUserId'
};

exports.Prisma.RoleScalarFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.RolePermissionScalarFieldEnum = {
  id: 'id',
  roleId: 'roleId',
  permission: 'permission'
};

exports.Prisma.FacilitatorScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  accessNIno: 'accessNIno',
  completedAccessNI: 'completedAccessNI',
  completedStaffObservation: 'completedStaffObservation',
  completedTraining: 'completedTraining',
  specialTraining: 'specialTraining',
  trainingExpiry: 'trainingExpiry',
  specialTrainingExpiry: 'specialTrainingExpiry',
  hasExperience: 'hasExperience',
  hasOtherTraining: 'hasOtherTraining',
  hasLaptopAccess: 'hasLaptopAccess',
  hasWellreadAccess: 'hasWellreadAccess',
  hasZoomUse: 'hasZoomUse',
  canShareZoomScreen: 'canShareZoomScreen',
  hasTechnologyUse: 'hasTechnologyUse',
  organisationId: 'organisationId',
  sessionId: 'sessionId'
};

exports.Prisma.FacilitatorToProgrammeScalarFieldEnum = {
  id: 'id',
  facilitatorId: 'facilitatorId',
  programmeId: 'programmeId',
  optedOutAdmit: 'optedOutAdmit',
  organisationId: 'organisationId'
};

exports.Prisma.SessionScalarFieldEnum = {
  id: 'id',
  mappedStoryId: 'mappedStoryId',
  state: 'state',
  currentPart: 'currentPart',
  currentQuestion: 'currentQuestion',
  date: 'date',
  isRegisterTaken: 'isRegisterTaken',
  isPrepared: 'isPrepared',
  sessionNumber: 'sessionNumber',
  isUsersAdmitted: 'isUsersAdmitted',
  isSurveyInProgress: 'isSurveyInProgress',
  programmeId: 'programmeId',
  organisationId: 'organisationId',
  guid: 'guid',
  isOnline: 'isOnline',
  storyQualityMeasure: 'storyQualityMeasure',
  annotationQualityMeasure: 'annotationQualityMeasure',
  groupWellbeingQualityMeasure: 'groupWellbeingQualityMeasure',
  providerType: 'providerType',
  providerURL: 'providerURL',
  dateStarted: 'dateStarted',
  completionTime: 'completionTime'
};

exports.Prisma.MappedStoryScalarFieldEnum = {
  id: 'id',
  order: 'order',
  storyId: 'storyId',
  storyCollectionId: 'storyCollectionId',
  published: 'published',
  createdDate: 'createdDate',
  publishedDate: 'publishedDate',
  organisationId: 'organisationId'
};

exports.Prisma.RelatedStoryScalarFieldEnum = {
  id: 'id',
  order: 'order',
  mappedStoryId: 'mappedStoryId',
  relatedStoryId: 'relatedStoryId'
};

exports.Prisma.ThemeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  colour: 'colour',
  organisationId: 'organisationId'
};

exports.Prisma.FacilitatorDayScalarFieldEnum = {
  id: 'id',
  facilitatorId: 'facilitatorId',
  organisationId: 'organisationId',
  day: 'day',
  partOfDay: 'partOfDay'
};

exports.Prisma.ProgrammeScalarFieldEnum = {
  name: 'name',
  isGroup: 'isGroup',
  ageRange: 'ageRange',
  startDate: 'startDate',
  storyCollectionId: 'storyCollectionId',
  organisationId: 'organisationId',
  id: 'id',
  isOnline: 'isOnline',
  surveyQuestions: 'surveyQuestions',
  preSurveyId: 'preSurveyId',
  postSurveyId: 'postSurveyId',
  providerType: 'providerType',
  providerURL: 'providerURL',
  state: 'state',
  deleted: 'deleted',
  deletedDate: 'deletedDate',
  classroomId: 'classroomId',
  frequency: 'frequency'
};

exports.Prisma.StoryScalarFieldEnum = {
  id: 'id',
  reviewedById: 'reviewedById',
  publishedById: 'publishedById',
  createdById: 'createdById',
  synopsis: 'synopsis',
  overview: 'overview',
  isReviewed: 'isReviewed',
  isPublished: 'isPublished',
  ageRange: 'ageRange',
  storyName: 'storyName',
  author: 'author',
  resources: 'resources',
  resourcesROI: 'resourcesROI',
  colour: 'colour',
  type: 'type',
  createdDate: 'createdDate',
  reviewedDate: 'reviewedDate',
  publishedDate: 'publishedDate',
  organisationId: 'organisationId'
};

exports.Prisma.ParticipantScalarFieldEnum = {
  id: 'id',
  name: 'name',
  email: 'email',
  phoneNumber: 'phoneNumber',
  lastInvolvement: 'lastInvolvement',
  organisationId: 'organisationId',
  publicUserId: 'publicUserId',
  gender: 'gender'
};

exports.Prisma.ParticipantToProgrammeScalarFieldEnum = {
  id: 'id',
  participantId: 'participantId',
  programmeId: 'programmeId',
  hasCompletedPreSurvey: 'hasCompletedPreSurvey',
  hasCompletedPostSurvey: 'hasCompletedPostSurvey',
  hasOptedOutOfSurveys: 'hasOptedOutOfSurveys',
  preReminderSentCount: 'preReminderSentCount',
  postReminderSentCount: 'postReminderSentCount'
};

exports.Prisma.OrganisationScalarFieldEnum = {
  id: 'id',
  name: 'name',
  phoneNo: 'phoneNo',
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2',
  city: 'city',
  postcode: 'postcode',
  country: 'country',
  isActiveSubscription: 'isActiveSubscription',
  subscriptionId: 'subscriptionId',
  sessionId: 'sessionId',
  stripeCustomerId: 'stripeCustomerId',
  stripePaymentMethod: 'stripePaymentMethod',
  trialEndDate: 'trialEndDate',
  subscriptionStatus: 'subscriptionStatus',
  deleted: 'deleted',
  tierId: 'tierId',
  orgType: 'orgType'
};

exports.Prisma.StripeSubscriptionScalarFieldEnum = {
  id: 'id',
  sessionId: 'sessionId',
  orgName: 'orgName',
  phoneNo: 'phoneNo',
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2',
  city: 'city',
  postcode: 'postcode',
  country: 'country',
  orgType: 'orgType',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  password: 'password',
  paymentMethod: 'paymentMethod'
};

exports.Prisma.CurriculumScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  purpose: 'purpose',
  ageRange: 'ageRange',
  colour: 'colour',
  pickOrder: 'pickOrder',
  organisationId: 'organisationId'
};

exports.Prisma.WeekScalarFieldEnum = {
  id: 'id',
  learningObjective: 'learningObjective',
  order: 'order',
  collectionId: 'collectionId',
  themeId: 'themeId',
  organisationId: 'organisationId'
};

exports.Prisma.SurveyMonkeyMappingScalarFieldEnum = {
  id: 'id',
  surveyMonkeyId: 'surveyMonkeyId',
  surveyGroupingId: 'surveyGroupingId',
  questionId: 'questionId',
  surveyType: 'surveyType'
};

exports.Prisma.SurveyScalarFieldEnum = {
  id: 'id',
  preSurveyMonkeyId: 'preSurveyMonkeyId',
  postSurveyMonkeyId: 'postSurveyMonkeyId',
  preSurveyId: 'preSurveyId',
  postSurveyId: 'postSurveyId',
  ageRange: 'ageRange',
  organisationId: 'organisationId',
  preGenderQuestionId: 'preGenderQuestionId',
  postGenderQuestionId: 'postGenderQuestionId'
};

exports.Prisma.FacilitatorStoryPartScalarFieldEnum = {
  id: 'id',
  html: 'html',
  mappedStoryPartId: 'mappedStoryPartId',
  facilitatorId: 'facilitatorId',
  organisationId: 'organisationId'
};

exports.Prisma.MappedStoryPartScalarFieldEnum = {
  id: 'id',
  content: 'content',
  figurativeNotes: 'figurativeNotes',
  storyId: 'storyId',
  part: 'part',
  organisationId: 'organisationId',
  imageUrl: 'imageUrl'
};

exports.Prisma.MappedStoryQuestionScalarFieldEnum = {
  id: 'id',
  mappedStoryPartId: 'mappedStoryPartId',
  mappedStoryId: 'mappedStoryId',
  order: 'order',
  category: 'category',
  question: 'question',
  explaination: 'explaination',
  hint1: 'hint1',
  hint2: 'hint2',
  prepGuide: 'prepGuide',
  prompt: 'prompt',
  keywords: 'keywords',
  keywordDesc: 'keywordDesc',
  organisationId: 'organisationId'
};

exports.Prisma.RelatedQuestionScalarFieldEnum = {
  id: 'id',
  condition: 'condition',
  question: 'question',
  explanation: 'explanation',
  mappedStoryQuestionId: 'mappedStoryQuestionId'
};

exports.Prisma.StoryCollectionScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  curriculumId: 'curriculumId',
  ageRange: 'ageRange',
  organisationId: 'organisationId',
  reviewed: 'reviewed',
  reviewedById: 'reviewedById',
  approved: 'approved',
  approvedById: 'approvedById',
  specialTraining: 'specialTraining',
  createdById: 'createdById',
  createdDate: 'createdDate',
  approvedDate: 'approvedDate',
  guideURL: 'guideURL',
  guideURLROI: 'guideURLROI',
  type: 'type',
  colour: 'colour'
};

exports.Prisma.PublicUserScalarFieldEnum = {
  id: 'id',
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phoneNumber: 'phoneNumber',
  isVerified: 'isVerified',
  isOnboardingComplete: 'isOnboardingComplete'
};

exports.Prisma.ParticipantToSessionScalarFieldEnum = {
  id: 'id',
  participantId: 'participantId',
  sessionId: 'sessionId',
  storyQualityMeasure: 'storyQualityMeasure',
  annotationQualityMeasure: 'annotationQualityMeasure',
  wellbeingQualityMeasure: 'wellbeingQualityMeasure',
  isPresent: 'isPresent'
};

exports.Prisma.ReadAtHomeSurveyScalarFieldEnum = {
  id: 'id',
  publicUserId: 'publicUserId',
  mappedStoryId: 'mappedStoryId',
  storyFeelingMeasure: 'storyFeelingMeasure',
  storyQualityMeasure: 'storyQualityMeasure',
  conversationMeasure: 'conversationMeasure'
};

exports.Prisma.SurveyGroupingScalarFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.SurveyAggregatesScalarFieldEnum = {
  id: 'id',
  surveyMonkeyId: 'surveyMonkeyId',
  programmeId: 'programmeId',
  participantId: 'participantId',
  surveyGroupingId: 'surveyGroupingId',
  surveyType: 'surveyType',
  sum: 'sum'
};

exports.Prisma.TierScalarFieldEnum = {
  id: 'id',
  organisationId: 'organisationId',
  tier: 'tier'
};

exports.Prisma.UserRatingScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  organisationId: 'organisationId',
  rating: 'rating',
  message: 'message',
  ratingDate: 'ratingDate'
};

exports.Prisma.TopicScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  colour: 'colour',
  organisationId: 'organisationId'
};

exports.Prisma.UserOnboardingScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  organisationId: 'organisationId',
  eploreComplete: 'eploreComplete',
  dashboardComplete: 'dashboardComplete',
  programmesComplete: 'programmesComplete'
};

exports.Prisma.ClassroomScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  ageRange: 'ageRange',
  deleted: 'deleted',
  deletedDate: 'deletedDate',
  primaryUserId: 'primaryUserId',
  organisationId: 'organisationId'
};

exports.Prisma.ParticipantToClassroomScalarFieldEnum = {
  id: 'id',
  participantId: 'participantId',
  classroomId: 'classroomId'
};

exports.Prisma.MoodScalarFieldEnum = {
  id: 'id',
  mood: 'mood',
  reason: 'reason',
  trigger: 'trigger',
  date: 'date',
  isPresent: 'isPresent',
  particpantToClassroomId: 'particpantToClassroomId',
  sessionId: 'sessionId',
  type: 'type',
  resolved: 'resolved',
  requiresResolution: 'requiresResolution',
  resolvedReason: 'resolvedReason'
};

exports.Prisma.EmotionThresholdScalarFieldEnum = {
  id: 'id',
  emotionCount: 'emotionCount',
  emotionFrequency: 'emotionFrequency',
  emotionTimeframe: 'emotionTimeframe',
  organisationId: 'organisationId',
  classroomId: 'classroomId'
};

exports.Prisma.EmailNotificationLogScalarFieldEnum = {
  id: 'id',
  teacher: 'teacher',
  teacherDateSent: 'teacherDateSent',
  adminThreshold: 'adminThreshold',
  adminThresholdDateSent: 'adminThresholdDateSent',
  adminRedFlags: 'adminRedFlags',
  adminRedFlagsDateSent: 'adminRedFlagsDateSent',
  organisationId: 'organisationId',
  classroomId: 'classroomId'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.NullableJsonNullValueInput = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};

exports.Prisma.JsonNullValueFilter = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull,
  AnyNull: Prisma.AnyNull
};
exports.RoleName = exports.$Enums.RoleName = {
  Admin: 'Admin',
  StorySelector: 'StorySelector',
  StoryAdmin: 'StoryAdmin',
  StoryAnnotator: 'StoryAnnotator',
  StoryPublisher: 'StoryPublisher',
  ProgrammeCoordinator: 'ProgrammeCoordinator',
  Facilitator: 'Facilitator'
};

exports.Permission = exports.$Enums.Permission = {
  FullAccess: 'FullAccess',
  StoryCreate: 'StoryCreate',
  StoryUpdate: 'StoryUpdate',
  StoryDelete: 'StoryDelete',
  StoryView: 'StoryView',
  StoryViewAll: 'StoryViewAll',
  StoryReview: 'StoryReview',
  StoryPublish: 'StoryPublish',
  StoryAddQuestion: 'StoryAddQuestion',
  StoryCollectionCreate: 'StoryCollectionCreate',
  StoryCollectionUpdate: 'StoryCollectionUpdate',
  StoryCollectionView: 'StoryCollectionView',
  StoryCollectionReview: 'StoryCollectionReview',
  StoryCollectionPublish: 'StoryCollectionPublish',
  DashboardView: 'DashboardView',
  ProgrammeCreate: 'ProgrammeCreate',
  ProgrammeView: 'ProgrammeView',
  ProgrammeViewAll: 'ProgrammeViewAll',
  ProgrammeUpdate: 'ProgrammeUpdate',
  ProgrammeReportView: 'ProgrammeReportView',
  FacilitatorCreate: 'FacilitatorCreate',
  FacilitatorDelete: 'FacilitatorDelete',
  FacilitatorUpdate: 'FacilitatorUpdate',
  FacilitatorView: 'FacilitatorView',
  FacilitatorViewAll: 'FacilitatorViewAll',
  SettingsView: 'SettingsView',
  UserCreate: 'UserCreate',
  UserView: 'UserView',
  UserViewAll: 'UserViewAll',
  UserDelete: 'UserDelete',
  UserUpdate: 'UserUpdate',
  RoleView: 'RoleView',
  RoleViewAll: 'RoleViewAll',
  ParticipantCreate: 'ParticipantCreate',
  ParticipantView: 'ParticipantView',
  ParticipantViewAll: 'ParticipantViewAll',
  ParticipantDelete: 'ParticipantDelete',
  ParticipantUpdate: 'ParticipantUpdate',
  SessionView: 'SessionView',
  SessionUpdate: 'SessionUpdate',
  SessionReschedule: 'SessionReschedule',
  SessionCreate: 'SessionCreate',
  SessionDelete: 'SessionDelete',
  SessionViewAll: 'SessionViewAll',
  ClassroomView: 'ClassroomView',
  ClassroomViewAll: 'ClassroomViewAll',
  ClassroomCreate: 'ClassroomCreate',
  ClassroomUpdate: 'ClassroomUpdate',
  ClassroomReportView: 'ClassroomReportView',
  VerbalManageTraining: 'VerbalManageTraining',
  VerbalOrganisationViewAll: 'VerbalOrganisationViewAll',
  VerbalCrossOrgFacilitator: 'VerbalCrossOrgFacilitator',
  StatsViewAll: 'StatsViewAll'
};

exports.SessionState = exports.$Enums.SessionState = {
  NotStarted: 'NotStarted',
  InProgress: 'InProgress',
  Complete: 'Complete'
};

exports.ConferenceProvider = exports.$Enums.ConferenceProvider = {
  BuiltIn: 'BuiltIn',
  GoogleMeet: 'GoogleMeet',
  MicrosoftTeams: 'MicrosoftTeams',
  Zoom: 'Zoom',
  Other: 'Other'
};

exports.CardColour = exports.$Enums.CardColour = {
  Red: 'Red',
  Orange: 'Orange',
  Yellow: 'Yellow',
  Green: 'Green',
  Cyan: 'Cyan',
  Blue: 'Blue',
  Magneta: 'Magneta',
  Crimson: 'Crimson',
  Silver: 'Silver',
  Bronze: 'Bronze',
  BrightYellow: 'BrightYellow',
  Aqua: 'Aqua',
  Purple: 'Purple',
  BrightOrange: 'BrightOrange',
  BrightRed: 'BrightRed',
  LightGreen: 'LightGreen',
  NightBlue: 'NightBlue'
};

exports.Day = exports.$Enums.Day = {
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday'
};

exports.PartOfDay = exports.$Enums.PartOfDay = {
  Morning: 'Morning',
  Afternoon: 'Afternoon',
  Evening: 'Evening'
};

exports.ProgrammeFrequency = exports.$Enums.ProgrammeFrequency = {
  Daily: 'Daily',
  Weekly: 'Weekly',
  Fortnightly: 'Fortnightly',
  Monthly: 'Monthly'
};

exports.StoryType = exports.$Enums.StoryType = {
  ShortStory: 'ShortStory',
  Extract: 'Extract'
};

exports.Gender = exports.$Enums.Gender = {
  Male: 'Male',
  Female: 'Female',
  PreferNotToSay: 'PreferNotToSay',
  Other: 'Other'
};

exports.OrganisationType = exports.$Enums.OrganisationType = {
  School: 'School',
  Commercial: 'Commercial',
  NonProfit: 'NonProfit',
  PublicSector: 'PublicSector',
  Other: 'Other'
};

exports.SurveyType = exports.$Enums.SurveyType = {
  Pre: 'Pre',
  Post: 'Post'
};

exports.QuestionCategory = exports.$Enums.QuestionCategory = {
  Closed: 'Closed',
  Open: 'Open'
};

exports.QuestionCondition = exports.$Enums.QuestionCondition = {
  Yes: 'Yes',
  No: 'No'
};

exports.StoryCollectionType = exports.$Enums.StoryCollectionType = {
  Public: 'Public',
  Group: 'Group'
};

exports.TierType = exports.$Enums.TierType = {
  Demo: 'Demo',
  Full: 'Full'
};

exports.ResolvedReason = exports.$Enums.ResolvedReason = {
  ResolvedSpokenDirect: 'ResolvedSpokenDirect',
  ResolvedExternal: 'ResolvedExternal',
  ResolvedOther: 'ResolvedOther',
  Unresolved: 'Unresolved',
  NA: 'NA'
};

exports.EmotionTimeframe = exports.$Enums.EmotionTimeframe = {
  Days: 'Days',
  Weeks: 'Weeks',
  Months: 'Months'
};

exports.Prisma.ModelName = {
  User: 'User',
  Role: 'Role',
  RolePermission: 'RolePermission',
  Facilitator: 'Facilitator',
  FacilitatorToProgramme: 'FacilitatorToProgramme',
  Session: 'Session',
  MappedStory: 'MappedStory',
  RelatedStory: 'RelatedStory',
  Theme: 'Theme',
  FacilitatorDay: 'FacilitatorDay',
  Programme: 'Programme',
  Story: 'Story',
  Participant: 'Participant',
  ParticipantToProgramme: 'ParticipantToProgramme',
  Organisation: 'Organisation',
  StripeSubscription: 'StripeSubscription',
  Curriculum: 'Curriculum',
  Week: 'Week',
  SurveyMonkeyMapping: 'SurveyMonkeyMapping',
  Survey: 'Survey',
  FacilitatorStoryPart: 'FacilitatorStoryPart',
  MappedStoryPart: 'MappedStoryPart',
  MappedStoryQuestion: 'MappedStoryQuestion',
  RelatedQuestion: 'RelatedQuestion',
  StoryCollection: 'StoryCollection',
  PublicUser: 'PublicUser',
  ParticipantToSession: 'ParticipantToSession',
  ReadAtHomeSurvey: 'ReadAtHomeSurvey',
  SurveyGrouping: 'SurveyGrouping',
  SurveyAggregates: 'SurveyAggregates',
  Tier: 'Tier',
  UserRating: 'UserRating',
  Topic: 'Topic',
  UserOnboarding: 'UserOnboarding',
  Classroom: 'Classroom',
  ParticipantToClassroom: 'ParticipantToClassroom',
  Mood: 'Mood',
  EmotionThreshold: 'EmotionThreshold',
  EmailNotificationLog: 'EmailNotificationLog'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        let message
        const runtime = getRuntime()
        if (runtime.isEdge) {
          message = `PrismaClient is not configured to run in ${runtime.prettyName}. In order to run Prisma Client on edge runtime, either:
- Use Prisma Accelerate: https://pris.ly/d/accelerate
- Use Driver Adapters: https://pris.ly/d/driver-adapters
`;
        } else {
          message = 'PrismaClient is unable to run in this browser environment, or has been bundled for the browser (running in `' + runtime.prettyName + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://pris.ly/prisma-prisma-bug-report`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
