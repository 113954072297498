import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { ListProvider } from "./contexts/list.context";
import { FetchProvider } from "./contexts/fetch.context";
import Edit from "./Edit";
import List from "./List";
import Add from "./Add";
import View, { ViewProps } from "./View";
import { CrudProvider } from "./contexts/crud.context";
import StringSchema from "yup/lib/string";
import { BaseRoute } from "../../../components/routing/PrivateRoute";
import { Permission } from "common/build/prisma/client";
import { ListParameters } from "common/build/api-parameters/common";
import { Grid, GridSize } from "@material-ui/core";

export type Action<T> = {
    label: string;
    onClick: (id: number, item: T) => void;
    visible?: (item: T) => boolean;
};

export type ModelField<T, F> = {
    decorator?: (value: F, property: string, data: T) => JSX.Element | string;
    inputValueDecorator?: (value: F, values: T) => unknown;
    submitValueDecorator?: (value: F, values: T) => unknown;
    inTable?: boolean;
    inExport?: boolean;
    inForm?: boolean;
    label?: string;
    validation?: StringSchema<string | undefined, Record<string, unknown>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    InputComponent?: (props: any) => JSX.Element;
    inputProps?: Record<string, unknown>;
    inputGroup?: Record<string, unknown>;
    disabled?: (data: T) => boolean;
};

type ModelTyped<T extends Record<string, unknown>> = {
    [k in keyof T]?: ModelField<T, T[k]>;
};

type ModelUnTyped<T extends Record<string, unknown>> = {
    [key: string]: ModelField<T, unknown>;
};

export type Model<T extends Record<string, unknown>> = ModelTyped<T> | Omit<ModelUnTyped<T>, keyof T>;

type CrudProps<T extends Record<string, unknown>, LP> = {
    noun: string;
    plural?: string;
    model: Model<T>;
    getData: (args: Record<string, unknown>) => Promise<{ items: T[]; total: number }>;
    getItem: (id: number, args: Record<string, unknown>) => Promise<T & { [key: string]: unknown }>;
    addItem?: (values: T) => Promise<Response>;
    updateItem?: (id: number, values: T) => Promise<Response>;
    deleteData?: (id: number) => Promise<Response>;
    viewOnClick?: boolean;
    canDelete?: boolean;
    labelProperty?: string;
    actions?: Action<T>[];
    listParams?: LP;
    itemParams?: Record<string, unknown>;
    searchable?: boolean;
    viewComponent?: React.FC<ViewProps>;
    addComponent?: React.FC<ViewProps>;
    editComponent?: React.FC<ViewProps & { canDelete?: boolean; actions?: Action<T>[] }>;
    canAdd?: boolean;
    orgFilterPermission?: Permission;
    programmeFilter?: boolean;
    userFilter?: boolean;
    canExport?: boolean;
    canOrder?: boolean;
    canFilterLocation?: boolean;
    canFilterActivity?: boolean;
    orgCanFilterArchive?: boolean;
};

export const makeGroup = (options?: Record<string, unknown>, inputs?: JSX.Element[]): JSX.Element | undefined => {
    if (options && inputs) {
        const { row, ...otherOptions } = options;

        if (inputs.length % 2 === 0) {
        }
        const size = row ? Math.round(12 / inputs.length) : 12;
        const gridSize = (size > 12 ? 12 : size) as GridSize;

        return (
            <>
                {inputs.map((input, index) => (
                    <Grid key={index} item xs={gridSize} {...otherOptions}>
                        {input}
                    </Grid>
                ))}
            </>
        );
    }
};

export const Crud = <T extends { id: number }, LP extends ListParameters>(props: CrudProps<T, LP>): JSX.Element => {
    const {
        model,
        noun,
        plural = `${noun}s`,
        getData,
        getItem,
        addItem,
        canAdd,
        updateItem,
        deleteData,
        viewOnClick,
        labelProperty,
        actions,
        listParams,
        itemParams = {},
        searchable = false,
        orgFilterPermission,
        programmeFilter,
        userFilter,
        viewComponent: ViewComponent = View,
        addComponent: AddComponent = Add,
        editComponent: EditComponent = Edit,
        canExport,
        canOrder,
        canDelete,
        canFilterLocation,
        canFilterActivity,
        orgCanFilterArchive,
    } = props;

    const { path } = useRouteMatch();

    return (
        <CrudProvider
            noun={noun}
            model={model}
            addItem={addItem}
            updateItem={updateItem}
            deleteData={deleteData}
            orgFilterPermission={orgFilterPermission}
            programmeFilter={programmeFilter}
            userFilter={userFilter}
            canOrder={canOrder}
            canFilterLocation={canFilterLocation}
            canFilterActivity={canFilterActivity}
            orgCanFilterArchive={orgCanFilterArchive}
        >
            <FetchProvider noun={noun} getItem={getItem} includes={itemParams}>
                <Switch>
                    <BaseRoute
                        path={`${path}`}
                        exact
                        permission={`${noun}ViewAll` as Permission}
                        component={() => (
                            <ListProvider plural={plural} getData={getData} includes={listParams}>
                                <List
                                    canEdit={updateItem ? true : false}
                                    canAdd={canAdd || addItem ? true : false}
                                    canView={viewOnClick}
                                    canExport={canExport}
                                    canDelete={canDelete}
                                    searchable={searchable}
                                />
                            </ListProvider>
                        )}
                    />

                    {updateItem && (
                        <BaseRoute
                            path={`${path}/edit/:id`}
                            exact
                            permission={`${noun}Update` as Permission}
                            component={() => <EditComponent<T> labelProperty={labelProperty} actions={actions} />}
                        />
                    )}
                    {(addItem || canAdd) && (
                        <BaseRoute
                            path={`${path}/add/:paramId?`}
                            exact
                            permission={`${noun}Create` as Permission}
                            component={() => <AddComponent<T> />}
                        />
                    )}
                    {viewOnClick && (
                        <BaseRoute
                            path={`${path}/view/:id`}
                            exact
                            permission={`${noun}View` as Permission}
                            component={() => <ViewComponent<T> labelProperty={labelProperty} />}
                        />
                    )}
                </Switch>
            </FetchProvider>
        </CrudProvider>
    );
};
export default Crud;
