import { Box, Grid, Hidden, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth.context";
import Breadcrumb from "../../core/components/Breadcrumb";
import { VList } from "../../core/components/VList";
import { VListItem, VListItemType } from "../../core/components/VListItem";
import { useCurriculum } from "../../hooks/useCurriculum";
import { useMobile } from "../../hooks/useMobile";
import { useOrganisation } from "../../hooks/useOrganisation";
import Spinner from "../layout/Spinner";
import { TabPanel } from "../programme/screens/ViewProgramme";
import { useHomeStyles } from "./Home.style";
import { HomeViewItem } from "./HomeViewItem";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Alert } from "@material-ui/lab";
import { ComingSoonCard } from "./components/ComingSoonCard";
import { StoryCollectionCard } from "./components/StoryCollectionCard";

export const ViewCurriculum = (): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const { curriculumInfo, getCurriculum } = useCurriculum();
    const { isTablet, isMobile } = useMobile();
    const { user } = useAuth();
    const { organisation, getOrganisation } = useOrganisation();

    const history = useHistory();

    const numberMissingCollections = curriculumInfo?.StoryCollection?.length
        ? 4 - curriculumInfo?.StoryCollection.length
        : -1;

    const [tabValue, setTabValue] = useState<number>(0);

    useEffect(() => {
        if (parseInt(id)) {
            getCurriculum(parseInt(id));
        }
    }, [id]);

    useEffect(() => {
        if (user) {
            getOrganisation(user.id);
        }
    }, [user]);

    const classes = useHomeStyles();

    return (
        <Box display={"flex"} width={"100%"} style={{ overflow: "hidden" }}>
            {curriculumInfo ? (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {!isTablet && isMobile && <div className={classes.toolbar} />}
                            <Box pl={!isTablet && isMobile ? 2 : 4} pt={!isTablet && isMobile ? 6 : 2}>
                                <Breadcrumb
                                    crumbs={[
                                        {
                                            name: `${"Explore"}`,
                                            url: `${"/explore"}`,
                                        },
                                    ]}
                                    current={""}
                                />
                            </Box>
                            <div id="curriculum-details" style={{ height: "95%" }}>
                                <HomeViewItem
                                    title={`${curriculumInfo?.name}`}
                                    description={curriculumInfo?.description}
                                    colour={curriculumInfo?.colour}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Hidden only={["sm", "xs"]}>
                                <Box display={"flex"} p={4} pt={0} id="curriculum-collections">
                                    <Grid container spacing={3} alignItems="stretch">
                                        <Grid item xs={12}>
                                            {organisation && organisation.Tier.tier === "Demo" && (
                                                <div className={classes.toolbar} />
                                            )}
                                            <Typography variant="h4">Collections in this curriculum</Typography>
                                        </Grid>
                                        {curriculumInfo.StoryCollection && curriculumInfo.StoryCollection.length > 0
                                            ? curriculumInfo.StoryCollection?.sort((a, b) =>
                                                  a.name < b.name ? 1 : 0,
                                              ).map((item, index) => (
                                                  <Grid item sm={3} key={item.id} style={{ display: "flex" }}>
                                                      <StoryCollectionCard
                                                          item={item}
                                                          curriculumId={curriculumInfo.id}
                                                          index={index}
                                                          curriculumColour={curriculumInfo.colour}
                                                      />
                                                  </Grid>
                                              ))
                                            : Array.from({ length: 4 }).map((_c, index) => (
                                                  <Grid item xs={3} key={index} style={{ display: "flex" }}>
                                                      <ComingSoonCard />
                                                  </Grid>
                                              ))}
                                        {numberMissingCollections > 0
                                            ? Array.from({ length: numberMissingCollections }).map((_c, index) => (
                                                  <Grid item xs={3} key={index} style={{ display: "flex" }}>
                                                      <ComingSoonCard />
                                                  </Grid>
                                              ))
                                            : null}
                                    </Grid>
                                </Box>
                            </Hidden>
                        </Grid>
                        <Hidden only={["md", "lg", "xl"]}>
                            <Box bgcolor="secondaryShade.main" width={1} px={2}>
                                <Grid item xs={12}>
                                    <Tabs
                                        textColor="primary"
                                        value={tabValue}
                                        onChange={(_event, newValue) => setTabValue(newValue)}
                                    >
                                        <Tab label={"Collections"} />
                                    </Tabs>
                                    <TabPanel value={tabValue} index={0}>
                                        <VList>
                                            {curriculumInfo.StoryCollection &&
                                            curriculumInfo.StoryCollection.length > 0 ? (
                                                curriculumInfo.StoryCollection?.map((item) => (
                                                    <Box mb={1} key={item.id}>
                                                        <Paper elevation={0}>
                                                            <VListItem
                                                                type={VListItemType.Primary}
                                                                primaryText={`${item.name} ${item.ageRange}`}
                                                                textProps={{ color: "primary" }}
                                                                onClick={() => {
                                                                    history.push({
                                                                        pathname: `/explore/curriculum/${curriculumInfo.id}/collection/${item.id}`,
                                                                    });
                                                                }}
                                                            >
                                                                <ArrowForwardIosIcon color="primary" />
                                                            </VListItem>
                                                        </Paper>
                                                    </Box>
                                                ))
                                            ) : (
                                                <Alert severity="info">Coming Soon....</Alert>
                                            )}
                                        </VList>
                                    </TabPanel>
                                </Grid>
                            </Box>
                        </Hidden>
                    </Grid>{" "}
                </>
            ) : (
                <Box m="auto" justifyContent="center">
                    <Spinner />
                </Box>
            )}
        </Box>
    );
};
